//
//
//
//
//
//
//

export default {
  name: 'AlgCopyrightText',
  methods: {
    getFullYear() {
      return new Date().getFullYear()
    },
  },
}

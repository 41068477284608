export default {
  name: 'FooterMixin',
  computed: {
    alternativeImageUrl() {
      const alternativeImg = this.$getBulletsMainSetting(
        'footerMenuImageFileSkolkovo'
      )
      if (alternativeImg[0]) {
        return alternativeImg[0].url
      }

      const deprecatedSetting = this.$getTextMainSetting('footerMenuImageFile')

      if (deprecatedSetting) {
        return deprecatedSetting
      }

      return ''
    },
  },
}

//
//
//
//
//
//
//
//
//

export default {
  name: 'AlgMotkaLink',
  props: {
    cssClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    designByText() {
      return this.$getTextMainSetting('footerDesignByLinkText').length > 0
        ? this.$getTextMainSetting('footerDesignByLinkText')
        : 'Design — Motka.design'
    },
  },
}

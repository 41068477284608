//
//
//
//
//
//
//
//
//

export default {
  name: 'AlgOfferLink',
  methods: {
    getOfferLink() {
      if (this.$route.path.includes('/camp')) {
        const offerFile = this.$getTextSetting('campOfferLink')
        if (offerFile?.url) {
          return offerFile?.url
        }
      }
      return this.$getTextMainSetting('offerLink')
    },
  },
}
